.zoneItem .gm-ui-hover-effect {
  display: none !important;
}

.zoneItem .gm-style .gm-style-iw-tc::after {
  height: 6px;
  left: 5px;
  top: -1px;
  width: 14px;
}
.zoneItem .gm-style-iw-d {
  overflow: hidden !important;
  max-height: 194px;
  padding: 0;
  width: 25px;
  margin: 0;
  height: 21px;
  display: flex;
  justify-content: center;
}
.zoneItem .gm-style-iw .gm-style-iw-c {
  padding: 0 !important;
}
.zoneItem .gm-style .gm-style-iw-c {
  cursor: pointer !important;
  padding: 0 !important;
}

.zoneItem .gm-style .gm-style-iw-c:hover {
  background-color: #dbdbdb !important;
}

.historyItem .gm-ui-hover-effect {
  scale: 1.5;
  border: 0px !important;
  margin: 30px !important;
  padding: 0px !important;
  top: 0px !important;
  right: 0px !important;
  width: 30px !important;
  height: 30px !important;
  background: antiquewhite !important;
  border-radius: 50% !important;
}

.map .lazy-load-image-background {
  @apply !w-full !h-full;
}
